import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  LIST_JOB_REFERRERS,
  LIST_REFERRERS,
  LIST_TAG,
  PIPELINE_CANDIDATE,
  PIPELINE_CANDIDATE_COUNTS,
} from "services/doverapi/endpointTagsConstants";
import {
  ApiApiAddCandidateInitialOutreachFlowRequest,
  AddCandidateInitialOutreachResponse,
  RetrieveEmailsForLinkedinPublicUrlResponse,
  ApiApiRetrieveEmailsForLinkedinPublicUrlOperationRequest,
  AddCandidateInitialOutreachBulkResponse,
  ApiApiAddCandidateInitialOutreachFlowBulkRequest,
  AddCandidateInterviewProcessResponse,
  ApiApiAddCandidateToInterviewProcessFlowRequest,
  ParseCandidateInfoResult,
  ApiApiParseCandidateInfoRequest,
  ApiApiExtensionV2CanSourceProfileRequest,
  CanSourceProfileResponse,
  ExtensionV2FindEmailsResponse,
  ApiApiExtensionV2FindEmailsOperationRequest,
} from "services/openapi";

const candidateEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    addCandidateInitialOutreach: build.mutation<
      AddCandidateInitialOutreachResponse,
      ApiApiAddCandidateInitialOutreachFlowRequest
    >({
      queryFn: async data => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const result = await client.addCandidateInitialOutreachFlow(data);
          return { data: result };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: () => {
        return [
          { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG },
          { type: PIPELINE_CANDIDATE, id: LIST_TAG },
        ];
      },
    }),
    addCandidateInitialOutreachBulk: build.mutation<
      AddCandidateInitialOutreachBulkResponse,
      ApiApiAddCandidateInitialOutreachFlowBulkRequest
    >({
      queryFn: async data => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const result = await client.addCandidateInitialOutreachFlowBulk(data);
          return { data: result };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: () => {
        return [
          { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG },
          { type: PIPELINE_CANDIDATE, id: LIST_TAG },
        ];
      },
    }),
    addCandidateInitialOutreachInterview: build.mutation<
      AddCandidateInterviewProcessResponse,
      ApiApiAddCandidateToInterviewProcessFlowRequest
    >({
      queryFn: async data => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const result = await client.addCandidateToInterviewProcessFlow(data);
          return { data: result };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: () => {
        return [
          { type: PIPELINE_CANDIDATE_COUNTS, id: LIST_TAG },
          { type: PIPELINE_CANDIDATE, id: LIST_TAG },
          { type: LIST_REFERRERS },
          { type: LIST_JOB_REFERRERS },
          { type: LIST_REFERRERS },
        ];
      },
    }),
    parseCandidateInfo: build.mutation<ParseCandidateInfoResult, ApiApiParseCandidateInfoRequest>({
      queryFn: async data => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const result = await client.parseCandidateInfo(data);
          return { data: result };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    retrieveEmailsForLinkedinPublicUrl: build.query<
      RetrieveEmailsForLinkedinPublicUrlResponse,
      ApiApiRetrieveEmailsForLinkedinPublicUrlOperationRequest
    >({
      queryFn: async data => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const result = await client.retrieveEmailsForLinkedinPublicUrl(data);
          return { data: result };
        } catch (error) {
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
    }),
    canSourceProfile: build.query<CanSourceProfileResponse, ApiApiExtensionV2CanSourceProfileRequest>({
      queryFn: async data => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const result = await client.extensionV2CanSourceProfile(data);
          return { data: result };
        } catch (error) {
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
    }),
    findEmailsFromLinkedInPublicUrl: build.query<
      ExtensionV2FindEmailsResponse,
      ApiApiExtensionV2FindEmailsOperationRequest
    >({
      queryFn: async data => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const result = await client.extensionV2FindEmails(data);
          return { data: result };
        } catch (error) {
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
    }),
  }),
});

export const {
  useAddCandidateInitialOutreachMutation,
  useAddCandidateInitialOutreachBulkMutation,
  useAddCandidateInitialOutreachInterviewMutation,
  useRetrieveEmailsForLinkedinPublicUrlQuery,
  useLazyRetrieveEmailsForLinkedinPublicUrlQuery,
  useLazyFindEmailsFromLinkedInPublicUrlQuery,
  useParseCandidateInfoMutation,
  useLazyCanSourceProfileQuery,
} = candidateEndpoints;
